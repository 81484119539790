import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/admin": [3],
		"/admin/profile-email": [4],
		"/admin/users": [5],
		"/book-college/[collegeId]": [6],
		"/chatrooms": [7],
		"/chatrooms/[chatroomId]": [8],
		"/email-test": [9],
		"/error-test": [10],
		"/magic-link-redirect": [~11],
		"/payment-redirect": [~12],
		"/privacy-policy": [13],
		"/private-route": [14],
		"/profile": [15],
		"/profile/account": [16],
		"/profile/availability": [17],
		"/profile/billing": [18],
		"/profile/students": [19],
		"/profile/tours": [20],
		"/redirect": [21],
		"/search": [22],
		"/sentry-example": [23],
		"/setup-profile": [24],
		"/signin": [~25],
		"/signin/forgot-password": [26],
		"/signup": [~27],
		"/signup/accept": [28],
		"/terms-conditions": [29],
		"/test-vonage": [31],
		"/test": [30],
		"/wait-enable": [32]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';